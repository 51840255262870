let exports = {};

exports.visualFormattingForPrices = (money, iso, precision, locale) => {
    if (window.isPointShop == "" && (typeof window.location.isPointShop == 'undefined' || window.location.isPointShop == "")) {
        return new Intl.NumberFormat(locale.replace("_","-"), {
            style: 'currency',
            currency: iso,
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        }).format(money);
    } else {
        return money + ' ' + iso
    }
};

export default exports;