<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="$emit('close')">
                <div class="modal-container" @click.stop="">
                    <div class="modal-header">
                        <slot name="header">
                            <h3>
                                <i class="far fa-frown"></i> {{$root.language.wsm_OutOfStock}}
                            </h3>
                            <p>{{productname}} </p>
                        </slot>
                    </div>
                    <div ref="modalBody" class="modal-body">
                        <slot name="body">
                            <transition-group :name="animation">
                                <div v-if="backorder && showStep1" class="step1" key="1">
                                    <p class="choose">{{$root.language.wsm_outOfStockOptions}}</p>
                                    <div class="backorderContent stepContent" @click="backOrderAdd">
                                        <p class="step">1</p>
                                        <div class="stepDescContainer">
                                            <p class="stepDesc">{{$root.language.wsm_AddToBasketModal}}</p>
                                            <p class="contentDesc">{{$root.language.wsm_backOrderDesc}}</p>
                                        </div>
                                    </div>
                                    <div class="getNotifiedContent stepContent" @click="stepForward('notify', 2)">
                                        <p class="step">{{backorder ? '2' : '1'}}</p>
                                        <div class="stepDescContainer">
                                            <p class="stepDesc">{{$root.language.wsm_Preorder}}</p>
                                            <p class="contentDesc">{{$root.language.wsm_preorderDesc}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="step2" v-if="showStep2" :key="2">
                                    <div class="step2Notify" v-if="choice == 'notify'">
                                        <h3 v-html="'<i class=fas fa-bell></i>' +$root.language.wsm_Preorder"></h3>
                                        <p>{{$root.language.wsm_preorderDesc}}</p>
                                    </div>
                                    <label>{{$root.language.wsm_YourEmail}}</label>
                                    <input type="text" v-model="mail" :placeholder="$root.language.wsm_YourEmail" required />
                                    <br />
                                    <label>{{$root.language.wsm_Name}}</label>
                                    <input type="text" v-model="name" :placeholder="$root.language.wsm_Name" required />
                                    <div class="newsletter">
                                        <input v-model="newsletterSignUp" id="newsletterChck" type="checkbox" />
                                        <label for="newsletterChck">{{$root.language.wsm_NewsletterOK}} - {{$root.language.wsm_checkOutNewsletterInput}}</label>
                                    </div>
                                    <button :disabled="(mail == '' || name == '') || !validEmail" @click="stepForward(choice, 3)" v-html="'<i class=fas fa-bell></i>' +$root.language.wsm_Preorder"></button>
                                </div>
                                <div class="step3" v-if="showStep3" :key="3">
                                    <div class="step3Complete">
                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                        </svg>
                                        <transition name="header-fade">
                                            <h3 v-if="completed">{{$root.language.wsm_preorderRequestReceived}}</h3>
                                        </transition>
                                        <transition name="header-fade">
                                            <button v-if="showCompleteBtn" @click="$emit('close')">{{$root.language.wsm_ContinueShopping_Checkout}}</button>
                                        </transition>
                                    </div>
                                </div>
                            </transition-group>
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <transition name="btn-fade">
                                <button class="modal-default-button backBtn" v-if="(currentStep > 1 && currentStep != 3) && (backorder && !preorder)" @click="stepBack(choice, currentStep-1)">
                                                                                                                                                                          <font-awesome-icon icon="arrow-left" /> {{$root.language.wsm_Back}}
                                                                                                                                                                        </button>
                            </transition>
                            <button v-if="currentStep != 3" class="modal-default-button" @click="$emit('close')">{{$root.language.wsm_Cancel}}</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: ["backorder", "preorder", "productid", "productname"],
        data() {
            return {
                showStep1: true,
                showStep2: false,
                showStep3: false,
                completed: false,
                showCompleteBtn: false,
                currentStep: 1,
                choice: "",
                animation: "slide-fade",
                mail: "",
                name: "",
                newsletterSignUp: false,
            };
        },
        mounted() {
            if (!this.backorder && this.preorder) {
                this.showStep1 = false;
                this.showStep2 = true;
                this.currentStep = 2;
                this.choice = 'notify'
            }
        },
        methods: {
            stepForward: function(typePicked, step) {
                this.animation = "slide-fade";
                switch (step) {
                    //add to basket (backorder) complete
                    case 2:
                        this.showStep1 = false;
                        setTimeout(() => {
                            this.showStep2 = true;
                        }, 310);
                        break;
                    case 3:
                        //preorder complete
                        axios.post(window.basedir + '/ajaxRequestServlet?mode=PreOrderProduct', {
                            productid: this.productid,
                            name: encodeURIComponent(this.name),
                            mail: this.mail,
                            newsletterSignUp: this.newsletterSignUp,
                        }, {
                            headers: {
                                'Content-type': 'application/json',
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            }
                        }).then(response => {
                            this.showStep2 = false;
                            document.getElementsByClassName("modal-body")[0].removeAttribute("style")
                            setTimeout(() => {
                                this.showStep3 = true;
                                setTimeout(() => {
                                    this.completed = true;
                                    setTimeout(() => {
                                        this.showCompleteBtn = true;
                                    }, 400)
                                }, 200);
                            }, 350);
                        })
                }
                this.choice = typePicked;
                this.currentStep = step;
            },
            stepBack: function(typePicked, step) {
                this.animation = "slide-fade2";
                switch (step) {
                    case 3:
                        this.showStep3 = false;
                        setTimeout(() => {
                            this.showStep2 = true;
                        }, 350);
                        break;
                    case 2:
                        this.showStep3 = false;
                        setTimeout(() => {
                            this.showStep2 = true;
                        }, 350);
                        break;
                    case 1:
                        this.showStep2 = false;
                        setTimeout(() => {
                            this.showStep1 = true;
                        }, 315);
                }
                this.choice = typePicked;
                this.currentStep = step;
            },
            backOrderAdd: function() {
                this.$emit('addToBasket');
                this.$emit('close');
            }
        },
        computed: {
            validEmail: function() {
                return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.mail);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        width: 100%;
        padding: 0;
        max-width: 22%;
        min-width: 553px;
        margin: 0px auto;
        position: relative;
        min-height: 540px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }
    .modal-header h3 {
        margin-top: 0;
        color: #004570;
        font-size: 1.5em;
        i {
            color: #004570;
            font-size: 1em;
        }
    }
    .modal-header {
        background: #f2f2f2;
        padding: 17px;
        border-bottom: 1px solid #d4d4d4;
        p {
            margin-left: 31px !important;
            width: 87%;
            color: #5e5e5e;
        }
    }
    .modal-footer {
        display: block;
        position: relative;
        width: calc(100% - 46px);
        height: 2.3rem;
        padding: 23px;
        padding-top: 0;
        .backBtn {
            float: left;
            i {
                color: white;
                vertical-align: text-bottom;
            }
        }
    }
    .modal-body {
        margin: 0;
        padding: 20px 30px;
        padding-top: 0;
        h3 {
            color: #004570;
            font-size: 2em;
            text-align: center;
            i {
                font-size: 1em;
                color: inherit;
            }
        }
        .step {
            background: white;
            border: 2px solid #7caf63;
            border-radius: 60px;
            display: inline-block;
            min-width: 44px;
            text-align: center;
            color: #7caf63;
            font-size: 2em;
            font-weight: bold;
            transition: all 0.2s ease-in-out;
            margin-left: -44px;
            margin-top: -6px;
        }
        .step1 {
            margin-top: 34px;
        }
        .choose {
            font-size: 1.2em;
            margin-left: 23px;
        }
        .stepDesc {
            display: inline-block;
            font-size: 1.4em;
            margin-left: 5px;
            font-weight: bold;
            color: #7caf63;
            text-transform: uppercase;
        }
        .contentDesc {
            font-size: 1.2em;
            color: #606060;
            margin-left: 8px;
        }
        .stepContent {
            margin-top: 17px;
            margin-bottom: 28px;
            padding: 20px;
            border: 2px solid white;
            transition: all 0.2s ease-in-out;
            margin-left: 15px;
            &:hover {
                cursor: pointer;
            }
        }
        .step3 {
            h3 {
                font-weight: bold;
                width: 80%;
                margin: 0 auto;
                margin-top: 20px;
                font-size: 1.5em;
                color: #7ac142;
            }
            button {
                background: #004570;
                border: none;
                color: white;
                padding: 10px;
                font-size: 1.2em;
                border-radius: 4px;
                display: block;
                margin: 0 auto;
                margin-top: 36px;
                box-shadow: 1px 1px 20px 0px #b2b2b2;
                cursor: pointer;
            }
        }
        .stepDescContainer {
            margin-left: 14px;
            margin-top: 1px;
            display: inline-block;
            width: 94%;
            vertical-align: top;
        }
        .getNotifiedContent {
            border: 2px solid #63afad;
            h3 {
                color: #63afad;
            }
            .step {
                border-color: #63afad;
                color: #63afad;
            }
            .stepDesc {
                color: #63afad;
            }
            &:hover {
                background: #cff3f2;
                .step {
                    background: #63afad;
                    color: white;
                }
            }
        }
        .backorderContent {
            border: 2px solid #7caf63;
            &:hover {
                background: #d9f2cd;
                .step {
                    background: #7caf63;
                    color: white;
                }
            }
        }
        .step2 {
            margin: 0 auto;
            display: block;
            padding: 57px;
            padding-top: 0;
            padding-bottom: 9px;
            &>div {
                margin-top: 10px;
                margin-bottom: 31px;
                p {
                    text-align: center;
                }
                h3 {
                    font-weight: bold;
                    font-size: 2em;
                }
            }
            button {
                background: #7caf63;
                border: none;
                color: white;
                padding: 10px;
                font-size: 1.2em;
                border-radius: 4px;
                display: block;
                margin: 0 auto;
                margin-top: 36px;
                box-shadow: 1px 1px 20px 0px #b2b2b2;
                cursor: pointer;
                &:disabled {
                    background: #6e6e6e;
                    opacity: 0.6;
                    &:hover {
                        background: #6e6e6e;
                    }
                }
                &:hover {
                    background: #97c580;
                }
            }
            .newsletter {
                text-align: left;
                width: 92%;
                margin: 0 auto;
                margin-top: 13px;
                input {
                    display: inline-block;
                    vertical-align: top;
                    padding-right: 20px;
                    margin-right: 8px;
                }
                label {
                    display: inline-block;
                    text-align: left;
                    width: 90%;
                    cursor: pointer;
                }
            }
            input[type="text"] {
                border-radius: 4px;
                font-size: 1.2em;
                transition: all 0.2s ease-in-out;
                width: 85%;
                padding: 10px;
                max-width: unset;
                text-align: center;
                color: #004570;
                margin: 0 auto;
                &:hover {
                    border: 1px solid #004570;
                }
                &:focus {
                    border: 1px solid #004570;
                }
            }
            label {
                display: none;
            }
            .step2Backorder {
                h3 {
                    color: #7caf63;
                }
                p {
                    color: #878f83;
                }
            }
            .step2Notify {
                h3 {
                    color: #63afad;
                }
                p {
                    color: #9db0b0;
                }
            }
        }
    }
    .modal-default-button {
        float: right;
        background: #004570;
        color: white;
        padding: 9px;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        &:hover {
            background: #206d9c;
        }
    }
    /*
                                                                                                                                             * The following styles are auto-applied to elements with
                                                                                                                                             * transition="modal" when their visibility is toggled
                                                                                                                                             * by Vue.js.
                                                                                                                                             *
                                                                                                                                             * You can easily play with the modal transition by editing
                                                                                                                                             * these styles.
                                                                                                                                             */
    .modal-enter {
        opacity: 0;
    }
    .modal-leave-active {
        opacity: 0;
    }
    .modal-enter .modal-container,
    .modal-leave-active .modal-container,
    .modal-leave-active {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active,
    .slide-fade-leave-active,
    .slide-fade2-enter-active,
    .slide-fade2-leave-active,
    .btn-fade-enter-active,
    .btn-fade-leave-active {
        transition: all 0.3s ease;
    }
    .header-fade-enter-active,
    .header-fade-leave-active {
        transition: all 1s ease;
    }
    .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(-10px);
        opacity: 0;
    }
    .slide-fade-enter
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
    .slide-fade2-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
    .slide-fade2-enter
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(-10px);
        opacity: 0;
    }
    .btn-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateY(10px);
        opacity: 0;
    }
    .btn-fade-enter
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateY(10px);
        opacity: 0;
    }
    .header-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateY(10px);
        opacity: 0;
    }
    .header-fade-enter
    /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateY(10px);
        opacity: 0;
    } // Colors
    .step3Complete {
        
    $green_backorder: #7ac142;
    $white: #fff; // Misc
    $curve: cubic-bezier(0.65, 0, 0.45, 1);
        .checkmark {
            width: 56px;
            height: 56px;
            margin: 0 auto;
            margin-top: 50px;
            border-radius: 50%;
            display: block;
            position: relative;
            stroke-width: 2;
            stroke: $white;
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px $green_backorder;
            animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
        }
        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: $green_backorder;
            fill: none;
            animation: stroke 0.6s $curve forwards;
        }
        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s $curve 0.8s forwards;
        }
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    @keyframes scale {
        0%,
        100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px $green_backorder;
        }
    }
    
    }
    @media screen and (max-width: 700px) {
        .modal-container {
            max-width: 80%;
            min-width: 80%;
            min-height: 447px;
            .stepDesc {
                font-size: 1em;
            }
            .contentDesc {
                font-size: 0.8em;
            }
            .stepDescContainer {
                width: 86%;
            }
            .stepDesc {
                font-size: 1em;
            }
            .contentDesc {
                font-size: 0.8em;
            }
        }
        .modal-body {
            .stepContent {
                padding-top: 7px;
                margin-left: 6px;
                padding-bottom: 10px;
            }
            .step {
                font-size: 1.4em;
                min-width: 29px;
                margin-left: -37px;
                margin-top: 6px;
            }
            .choose {
                margin: 0;
                text-align: center;
            }
            .step1 {
                margin-top: 15px;
                padding: 0;
            }
            .step2 {
                margin-top: 15px;
                padding: 0;
                button {
                    min-width: 91%;
                    margin-bottom: 20px;
                }
                .newsletter {
                    label {
                        width: 86%;
                        font-size: 0.8em;
                    }
                }
                .step2Notify {
                    font-size: 0.8em;
                    h3 {
                        font-size: 1.5em;
                    }
                }
            }
            .step3 {
                h3 {
                    width: 85%;
                }
            }
        }
        .modal-footer {
            display: block;
            width: 93%;
            position: relative;
            height: 30px;
            padding: 14px;
            padding-top: 0;
        }
    }
</style>
