var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid" }, [
    _c("div", { staticClass: "productContainer" }, [
      _vm.view == "grid"
        ? _c("div", { ref: "imageContainer", staticClass: "productImage" }, [
            this.$root.isNutriverse
              ? _c(
                  "a",
                  { attrs: { href: _vm.readMoreLink } },
                  [
                    this.$root.customimage == "false" ||
                    this.$root.customimage.length == 0 ||
                    typeof _vm.$root.customimage == "undefined"
                      ? _c("v-lazy-image", {
                          attrs: {
                            alt: _vm.convertToPlainText(_vm.product.name),
                            title: _vm.convertToPlainText(_vm.product.name),
                            "src-placeholder":
                              _vm.product.imageUrl + "?w=10&quality=1",
                            srcset: _vm.imageSet.set,
                            src: _vm.product.imageUrl + "?w=" + _vm.imageSize,
                            rel: _vm.preload ? "preload" : "",
                          },
                          on: { load: _vm.adjustHeight },
                        })
                      : _vm._e(),
                    typeof this.$root.customimage != "undefined" &&
                    this.$root.customimage == "true" &&
                    this.$root.customimage.length > 0
                      ? _c("v-lazy-image", {
                          attrs: {
                            alt: _vm.convertToPlainText(_vm.product.name),
                            title: _vm.convertToPlainText(_vm.product.name),
                            src: this.$root.customimgurl.includes("sg0")
                              ? _vm.$root.customimgurl + "?w=" + _vm.imageSize
                              : _vm.$root.customimgurl,
                            srcset: _vm.imageSet.set,
                            rel: _vm.preload ? "preload" : "",
                          },
                          on: { load: _vm.adjustHeight },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            !this.$root.isNutriverse
              ? _c(
                  "a",
                  { attrs: { href: _vm.readMoreLink } },
                  [
                    this.$root.customimage == "false" ||
                    this.$root.customimage.length == 0 ||
                    typeof _vm.$root.customimage == "undefined"
                      ? _c("v-lazy-image", {
                          attrs: {
                            alt: _vm.product.name,
                            title: _vm.product.name,
                            "src-placeholder":
                              _vm.product.imageUrl + "?w=10&quality=1",
                            srcset: _vm.imageSet.set,
                            src:
                              this.fetchImageUrl(
                                _vm.product.imageUrl,
                                _vm.product.name
                              ) +
                              "?w=" +
                              _vm.imageSize,
                            rel: _vm.preload ? "preload" : "",
                          },
                          on: { load: _vm.adjustHeight },
                        })
                      : _vm._e(),
                    typeof this.$root.customimage != "undefined" &&
                    this.$root.customimage == "true" &&
                    this.$root.customimage.length > 0
                      ? _c("v-lazy-image", {
                          attrs: {
                            alt: _vm.product.name,
                            title: _vm.product.name,
                            "src-placeholder":
                              this.$root.customimgurl + "?w=10&quality=1",
                            src: this.$root.customimgurl.includes("sg0")
                              ? this.fetchImageUrl(
                                  _vm.$root.customimgurl,
                                  _vm.product.name
                                ) +
                                "?w=" +
                                _vm.imageSize
                              : this.fetchImageUrl(
                                  _vm.$root.customimgurl,
                                  _vm.product.name
                                ),
                            srcset: _vm.imageSet.set,
                            rel: _vm.preload ? "preload" : "",
                          },
                          on: { load: _vm.adjustHeight },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "productInfo" }, [
        _c("h3", { domProps: { innerHTML: _vm._s(_vm.product.name) } }),
        typeof this.$root.customText == "undefined" ||
        this.$root.customText.length == 0
          ? _c("span", { domProps: { innerHTML: _vm._s(_vm.productDesc) } })
          : _vm._e(),
        typeof this.$root.customText != "undefined" &&
        this.$root.customText.length > 0
          ? _c("span", {
              domProps: { innerHTML: _vm._s(this.$root.customText) },
            })
          : _vm._e(),
        _c("div", { staticClass: "infoContainer" }, [
          _c(
            "a",
            {
              staticClass: "readMoreProductLink",
              attrs: {
                href: _vm.$root.pointshop
                  ? _vm.basedir +
                    _vm.$root.pointshopUrlPrefix +
                    _vm.product.productPageLink
                  : _vm.basedir +
                    "/" +
                    _vm.product.productPageLink +
                    (this.product.isAllowSubscription && _vm.$root.subenabled
                      ? "#variant=" + this.product.productId
                      : ""),
              },
            },
            [_vm._v(_vm._s(this.$root.language.wsm_MoreInfo))]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          class: [
            parseInt(_vm.product.discount) > 0 || _vm.product.taxRateFactor == 1
              ? "extraHeight"
              : "",
            "productActionBar",
          ],
        },
        [
          _c(
            "a",
            {
              attrs: {
                href: _vm.$root.pointshop
                  ? _vm.basedir +
                    _vm.$root.pointshopUrlPrefix +
                    _vm.product.productPageLink
                  : _vm.basedir +
                    "/" +
                    _vm.product.productPageLink +
                    (this.product.isAllowSubscription && _vm.$root.subenabled
                      ? "#variant=" + this.product.productId
                      : ""),
              },
            },
            [
              _c("span", {
                staticClass: "quantityProduct",
                domProps: { innerHTML: _vm._s(_vm.product.quantityString) },
              }),
            ]
          ),
          _vm.price != "NaN" && _vm.product.availability != 2
            ? _c("p", { staticClass: "productPrice" }, [
                _vm._v(_vm._s(_vm.getVisString(_vm.product.price))),
              ])
            : _vm._e(),
          (_vm.view == "grid" &&
            (_vm.product.discount > 0 || _vm.$root.iscompany)) ||
          (_vm.view == "list" && _vm.mobile)
            ? _c("additionalInfo", {
                attrs: {
                  data: _vm.product,
                  companyPriceIncludingVat: _vm.getVisString(
                    _vm.product.companyPriceIncludingVat
                  ),
                  listPrice: _vm.getVisString(_vm.product.listPrice),
                  localDiscount: _vm.getVisString(_vm.product.discount),
                  tax: _vm.product.taxRateFactor,
                  showBuyButton: _vm.showBuyButtonLogic,
                },
              })
            : _vm._e(),
          _vm.showBuyButtonLogic
            ? _c("addToBasket", {
                class: [
                  "addToBasketButton",
                  _vm.product.availability == 2 ? "nosale" : "",
                  _vm.$root.admin ? "adminBtn" : "",
                ],
                attrs: {
                  id: _vm.product.productId,
                  quantity: _vm.product.quantity,
                  availability: _vm.product.availability,
                  backorder: this.$root.backorder,
                  preorder: this.$root.preorder,
                  productname: _vm.product.name,
                },
                on: { setQuant: _vm.setQuant, gtag: _vm.gtag },
              })
            : _vm._e(),
          _vm.view == "grid" || (_vm.view == "grid" && _vm.mobile)
            ? _c(
                "a",
                {
                  staticClass: "infoLink",
                  style: !_vm.showBuyButtonLogic
                    ? "	float: right;margin-top: -11px;"
                    : "",
                  attrs: {
                    href: _vm.$root.pointshop
                      ? _vm.basedir +
                        _vm.$root.pointshopUrlPrefix +
                        _vm.product.productPageLink
                      : _vm.basedir + "/" + _vm.product.productPageLink,
                  },
                },
                [_vm._m(0)]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("script", { attrs: { type: "application/ld+json" } }, [
      _vm._v(
        ' [{ "@context": "http://schema.org", "@type": "Product", "description": "' +
          _vm._s(_vm.productDesc) +
          '", "name": "' +
          _vm._s(_vm.product.productName) +
          '", "image": "' +
          _vm._s(
            this.$root.customimage == "false" ||
              this.$root.customimage == "" ||
              typeof this.$root.customimage == "undefined"
              ? _vm.product.imageUrl
              : this.$root.customimgurl
          ) +
          '", "sku": "' +
          _vm._s(_vm.product.productID) +
          '", "brand": { "@type": "Brand", "name": "Pharma Nord" }, "mpn": "' +
          _vm._s(_vm.locale + _vm.product.productID) +
          '", "offers": { "@type": "Offer", "availability": "' +
          _vm._s(
            _vm.product.availability == 5
              ? "http://schema.org/InStock"
              : "http://schema.org/OutOfStock"
          ) +
          '", "price": ' +
          _vm._s(_vm.product.listPrice) +
          ', "priceCurrency": "' +
          _vm._s(_vm.product.CurrencyIso) +
          '", "url": "' +
          _vm._s(
            _vm.location.origin +
              _vm.basedir +
              "/" +
              _vm.product.productPageLink
          ) +
          '" } }] '
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infoBtn" }, [
      _c("i", { staticClass: "fa fa-info" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }