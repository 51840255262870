<template>
  <div v-if="showBuyButton">
    <div class="additionalInfo">
      <p v-if="data.discount > 0">
        <span class="discountInfo">{{listPrice}}</span>{{this.$root.language.wsm_YouSave}} {{localDiscount}}
      </p>
      <p v-if="$root.iscompany && tax > 1" class="inclVat">{{this.$root.language.wsm_InclVAT}} {{companyPriceIncludingVat}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      "data",
      "showBuyButton",
      "localDiscount",
      "listPrice",
      "companyPriceIncludingVat",
      "tax"
    ],
      computed: {
      locale: function(){
        return window.locale
      }
    }
  };
</script>

<style lang="scss" scoped>
.additionalInfo {
  width: 87%;
	display: inline;
}
  .discountInfo {
    text-decoration: line-through;
    min-width: 11%;
    display: inline-block;
    margin-right: 8px;
  }
  p,
  span {
    font-size: 11px;
    color: gray;
  }
</style>