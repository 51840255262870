import { render, staticRenderFns } from "./productWidget.vue?vue&type=template&id=3d168f8a&scoped=true"
import script from "./productWidget.vue?vue&type=script&lang=js"
export * from "./productWidget.vue?vue&type=script&lang=js"
import style0 from "./productWidget.vue?vue&type=style&index=0&id=3d168f8a&lang=scss&scoped=true"
import style1 from "./productWidget.vue?vue&type=style&index=1&id=3d168f8a&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d168f8a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/dvl/hghansen/compiere-all/pharmanord2011/template/webapps/productwidget/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d168f8a')) {
      api.createRecord('3d168f8a', component.options)
    } else {
      api.reload('3d168f8a', component.options)
    }
    module.hot.accept("./productWidget.vue?vue&type=template&id=3d168f8a&scoped=true", function () {
      api.rerender('3d168f8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/productWidget.vue"
export default component.exports