var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBuyButton
    ? _c("div", [
        _c("div", { staticClass: "additionalInfo" }, [
          _vm.data.discount > 0
            ? _c("p", [
                _c("span", { staticClass: "discountInfo" }, [
                  _vm._v(_vm._s(_vm.listPrice)),
                ]),
                _vm._v(
                  _vm._s(this.$root.language.wsm_YouSave) +
                    " " +
                    _vm._s(_vm.localDiscount) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.$root.iscompany && _vm.tax > 1
            ? _c("p", { staticClass: "inclVat" }, [
                _vm._v(
                  _vm._s(this.$root.language.wsm_InclVAT) +
                    " " +
                    _vm._s(_vm.companyPriceIncludingVat)
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }