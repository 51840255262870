var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    (((_vm.availability != 0 && !_vm.backorder) ||
      (_vm.availability == 0 && (_vm.backorder || _vm.preorder))) &&
      _vm.availability != 2) ||
    _vm.availability == 5
      ? _c(
          "div",
          { staticClass: "addtobasketbtn" },
          [
            _c(
              "transition-group",
              { attrs: { name: "bounce", duration: { enter: 300, leave: 0 } } },
              [
                _vm.showAddToBasket &&
                (((_vm.availability != 0 || _vm.availability != 2) &&
                  !_vm.backorder &&
                  !_vm.preorder) ||
                  _vm.availability == 5)
                  ? _c("button", {
                      key: 1,
                      staticClass: "add",
                      domProps: {
                        innerHTML: _vm._s(this.$root.language.wsm_AddToBasket),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addToBasket(_vm.id)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.availability == 0 &&
                (_vm.backorder || _vm.preorder) &&
                _vm.quantity == 0 &&
                _vm.showAddToBasket
                  ? _c("div", { key: 2 }, [
                      _vm.backorder
                        ? _c("button", {
                            staticClass: "add",
                            domProps: {
                              innerHTML: _vm._s(
                                this.$root.language.wsm_AddToBasket
                              ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.showModal = true
                              },
                            },
                          })
                        : _vm._e(),
                      !_vm.backorder && _vm.preorder
                        ? _c("button", {
                            staticClass: "add notify",
                            domProps: {
                              innerHTML: _vm._s(
                                "<i class=fas fa-bell></i>" +
                                  this.$root.language.wsm_Preorder
                              ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.showModal = true
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "transition",
              {
                attrs: {
                  name: _vm.entry ? "" : "bounce",
                  duration: { enter: 300, leave: 0 },
                },
              },
              [
                _vm.quantity != 0 && _vm.showQuant
                  ? _c(
                      "div",
                      {
                        staticClass: "quantityButtons",
                        class: { isSubscription: this.isSubscription },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "minus",
                            on: {
                              click: function ($event) {
                                return _vm.adjustQuantity("minus")
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-minus" })]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.quantity,
                              expression: "quantity",
                            },
                          ],
                          attrs: { disabled: "" },
                          domProps: { value: _vm.quantity },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.quantity = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "plus",
                            on: {
                              click: function ($event) {
                                return _vm.adjustQuantity("plus")
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-plus" })]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.availability == 0 && !_vm.backorder && !_vm.preorder
      ? _c("div", [
          _c("button", { staticClass: "add outOfStock" }, [
            _vm._v(_vm._s(this.$root.language.wsm_OutOfStock)),
          ]),
        ])
      : _vm._e(),
    _vm.availability == 0 && (_vm.backorder || _vm.preorder)
      ? _c(
          "div",
          [
            _vm.showModal
              ? _c("backorderModal", {
                  attrs: {
                    preorder: _vm.preorder,
                    productid: _vm.id,
                    backorder: _vm.backorder,
                    productname: _vm.productname,
                  },
                  on: {
                    addToBasket: _vm.addToBasket,
                    close: function ($event) {
                      _vm.showModal = false
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.availability == 2
      ? _c("div", [
          _c("button", { staticClass: "add outOfStock" }, [
            _vm._v(_vm._s(this.$root.language.wsm_NotForSale)),
          ]),
        ])
      : _vm._e(),
    _vm.$root.product.isAllowSubscription &&
    _vm.currentQuant > 0 &&
    _vm.subscriptionEnabled
      ? _c(
          "a",
          {
            staticClass: "savewithautoship",
            style:
              "" +
              ((_vm.$root.product.discount > 0 ||
                _vm.$root.display.iscompany) &&
              !_vm.$root.pointshop
                ? "margin-top: -1rem"
                : ""),
            attrs: {
              href: _vm.$root.pointshop
                ? _vm.basedir +
                  _vm.$root.pointshopUrlPrefix +
                  _vm.product.productPageLink
                : _vm.basedir +
                  "/" +
                  _vm.$root.product.productPageLink +
                  "#variant=" +
                  _vm.$root.product.productId +
                  "&savewithsub=true",
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.isSubscription
                  ? ""
                  : _vm.$root.language.wsm_sub_subavailable
              )
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }