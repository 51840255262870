<template>
    <div>
        <!-- Show buybutton if item is on stock and no backorder or show buybutton if item is out of stock but backorder is available. Also show if item has not been set to "may not sell"-->
        <div  class="addtobasketbtn" v-if="(((availability != 0 && !backorder) || (availability == 0 && (backorder || preorder))) && availability != 2) || availability == 5">
            <transition-group name="bounce" :duration="{ enter: 300, leave: 0 }">
                <button :key="1" class="add" v-if="(showAddToBasket && (((availability != 0 || availability != 2) && (!backorder && !preorder)) || (availability == 5)))" @click="addToBasket(id)" v-html="this.$root.language.wsm_AddToBasket"></button>
                <div :key="2" v-if="(availability == 0 && (backorder || preorder)) && quantity == 0 && showAddToBasket">
                    <button v-if="backorder" class="add" @click="showModal = true" v-html="this.$root.language.wsm_AddToBasket"></button>
                    <button v-if="!backorder && preorder" class="add notify" @click="showModal = true" v-html="'<i class=fas fa-bell></i>'+ this.$root.language.wsm_Preorder"></button>
                </div>
            </transition-group>
            <transition :name="entry ? '' : 'bounce'" :duration="{ enter: 300, leave: 0 }">
                <div class="quantityButtons" :class="{'isSubscription' : this.isSubscription}" v-if="quantity != 0 && showQuant">
                    <button class="minus" @click="adjustQuantity('minus')">
                                                                                                                                                <i class="fas fa-minus"></i>
                                                                                                                                              </button>
                    <input disabled v-model="quantity" />
                    <button class="plus" @click="adjustQuantity('plus')">
                                                                                                                                                <i class="fas fa-plus"></i>
                                                                                                                                              </button>
                </div>
            </transition>
        </div>
        <!-- Out of stock, no backorder -->
        <div v-if="availability == 0 && (!backorder && !preorder)">
            <button class="add outOfStock">{{this.$root.language.wsm_OutOfStock}}</button>
        </div>
        <!-- include backorder modal -->
        <div v-if="availability == 0 && (backorder || preorder)">
            <backorderModal @addToBasket="addToBasket" v-if="showModal" @close="showModal = false" :preorder="preorder" :productid="id" :backorder="backorder" :productname="productname"></backorderModal>
        </div>
        <!-- May not be sold, no backorder -->
        <div v-if="availability == 2">
            <button class="add outOfStock">{{this.$root.language.wsm_NotForSale}}</button>
        </div>
        <a class="savewithautoship" :style="`${ (($root.product.discount > 0 || $root.display.iscompany)) && !$root.pointshop ? 'margin-top: -1rem': ''}`" v-if="$root.product.isAllowSubscription && currentQuant > 0 && subscriptionEnabled" :href="$root.pointshop ? basedir+$root.pointshopUrlPrefix+product.productPageLink : basedir+'/'+$root.product.productPageLink+'#variant='+$root.product.productId+'&savewithsub=true'">{{ isSubscription ? '' : $root.language.wsm_sub_subavailable }}</a>

    </div>
</template>

<script>
    import backorderModal from "./backorder";
    export default {
        components: {
            backorderModal,
        },
        props: ["id", "quantity", "availability", "backorder", "preorder", "productname"],
        data() {
            return {
                currentQuant: 0,
                showAddToBasket: true,
                showModal: false,
                showQuant: "",
                entry: true,
                isSubscription: false,
            };
        },
        async created() {
            this.currentQuant = this.quantity;
            if(this.currentQuant) {
                let data = { refreshBasket: 'OK', isPointShop: window.isPointShop }
                let response = await axios.get(`${window.basedir}/ajaxRequestServlet?mode=RefreshBasket&payload=${JSON.stringify(data)}`);
                this.isSubscription = response.data.payload.basket.lines.find(x => x.productID == this.id).isSubscription
            }
        },
        watch: {
            quantity: {
                handler() {
                    this.currentQuant = this.quantity
                }
            },
            currentQuant: {
                handler() {
                    if (this.quantity == 0) {
                        this.showQuant = false;
                        setTimeout(() => {
                            this.showAddToBasket = true;
                        }, 100)
                    } else {
                        this.showAddToBasket = false;
                        setTimeout(() => {
                            this.showQuant = true;
                        }, 100)
                    }
                }
            }
        },
        computed: {
            basedir() {
                return window.basedir
            },
            subscriptionEnabled() {
                return window.subscriptionEnabled == 'true'
            }
        },
        methods: {
            addToBasket: function() {
                var _that = this;
                this.entry = false;
                axios.get(window.basedir + '/ajaxRequestServlet?mode=UpdateQuantity&payload={"products":[{productID:' + this.id + ',quantity:1}], "isPointShop":"' + (this.$root.pointshop ? 'Y' : false) + '"}', {
                    headers: {
                        'Content-type': 'application/json',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    }
                }).then((response) => {
                    if (response.data.errorMessage.length > 0) {
                        alertify.logPosition("bottom right").closeLogOnClick(true).delay(0).error('<i style="color:#ff7070 !important;padding-right: 3px;" class="fa fa-exclamation-triangle" aria-hidden="true"></i>  ' + response.data.errorMessage);
                    } else {
                        axios.get(window.basedir + '/ajaxRequestServlet?mode=RefreshBasket&payload={"refreshBasket":"OK","isPointShop":"' + (this.$root.pointshop ? 'Y' : false) + '"}', {
                            headers: {
                                'Content-type': 'application/json',
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            }
                        }).then(response => {
                            if (!response.data.hasOwnProperty('isEmpty')) {
                                response.data.payload.basket.lines.forEach(element => {
                                    if (element.productID == this.id) {

                                        this.isSubscription = element.isSubscription
                                        this.$emit("setQuant", parseInt(element.quantity))
                                        //this.currentQuant = parseInt(element.quantity)
                                    }
                                });
                            }
                            window.ajaxBasket.refresh()
                        })
                        this.$emit('gtag', this.id)
                    }
                });
            },
            adjustQuantity: function(type) {
                var amount = type == 'minus' ? parseInt((this.currentQuant - 1)) : parseInt((this.currentQuant + 1))
                axios.get(window.basedir + "/ajaxRequestServlet?mode=UpdateQuantity&payload={'products':[{productID:" + this.id + ",quantity:" + amount + "}], isPointShop: " + (this.$root.pointshop ? 'Y' : false) + "}", {
                    headers: {
                        'Content-type': 'application/json',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    }
                }).then(response => {
                    if (response.data.errorMessage.length > 0) {
                        alertify.logPosition("bottom right").closeLogOnClick(true).delay(0).error('<i style="color:#ff7070 !important;padding-right: 3px;" class="fa fa-exclamation-triangle" aria-hidden="true"></i>  ' + response.data.errorMessage);
                    } else {
                        axios.get(window.basedir + '/ajaxRequestServlet?mode=RefreshBasket&payload={"refreshBasket":"OK","isPointShop":"' + (this.$root.pointshop ? 'Y' : false) + '"}', {
                            headers: {
                                'Content-type': 'application/json',
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            }
                        }).then(response => {
                            if (amount == 0) {
                                this.$emit("setQuant", 0)
                            } else {
                                response.data.payload.basket.lines.forEach(element => {
                                    if (element.productID == this.id) {
                                        this.$emit("setQuant", parseInt(element.quantity))
                                    }
                                });
                            }
                            window.ajaxBasket.refresh()
                        })
                        this.$emit('gtag', this.id)
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.addtobasketbtn {
    min-height: 29px;
}
    .add {
        background-position: center !important;
        transition: background 0.2s !important;
        border-radius: 0px;
        padding: 3px 8px;
        -webkit-appearance: none;
        border: 1px solid darkseagreen;
        background: #7caf63;
        color: white;
        float: right;
        cursor: pointer;
        height: 100%;
        i {
            color: white;
            font-size: 0.8em;
            vertical-align: top;
            margin-top: 2px;
        }
    }
    .notify {
        background: #004570;
        border: 1px solid #004570;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            color:white;
            margin-left: 0.3rem;
        }
        &:hover {
            background: #206d9c;
            border: 1px solid #1c73a9;
        }
    }
    .nosale {
        .outOfStock {
            font-size: 0.7em;
            min-height: 22px;
        }
    }
    .outOfStock {
        background: transparent;
        color: #7caf63;
        border: 1px solid #7caf63;
        font-weight: bold;
        cursor: default;
    }
    .grid {
        .add {
            width: 97%;
            padding: 0;
            margin: 0;
            display: block;
            float: none;
            border-radius: 0;
            padding: 6px;
        }
        .quantityButtons {
            height: 29px;
            text-align: left;
            input {
                width: 12%;
                height: 23px;
                min-width: 32px;
                box-sizing: content-box;
            }
        }
    }
    .quantityButtons {
        text-align: right;
        height: 23px;
        button {
            height: 100%;
            background-position: center !important;
            transition: background 0.6s !important;
            border-radius: 3px;
            border-radius: 3px;
            border-radius: 3px;
            padding: 3px 8px;
            -webkit-appearance: none;
            border: 1px solid darkseagreen;
            background: #7caf63;
            color: white;
            display: inline-block;
            margin: 0;
            cursor: pointer;
            .fas {
                color: white;
                font-size: 0.8em;
            }
        }
        .plus {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .minus {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .quantityButtons.isSubscription {
            button {
                border: 1px solid #b68c3d;
	            background: #d8a64b !important;
            }
            input {
                border-top: 1px solid #b68c3d;
                border-bottom: 1px solid #b68c3d;
            }
        }
    input {
        width: 32%;
        display: inline-block;
        text-align: center;
        color: #004570;
        background: #ffffff;
        border-top: 1px solid #7caf63;
        border-bottom: 1px solid #7caf63;
        border-right: none;
        border-left: none;
        font-size: 1.1em;
        font-weight: bold;
        vertical-align: top;
        height: 17px;
        padding-top: 3px;
    }
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.02);
        }
        100% {
            transform: scale(1);
        }
    }
    @media screen and (max-width: 700px) {
        .grid {
            .addToBasketButton {
                .add {
                    width: 100%;
                    min-height: 29px;
                }
                .quantityButtons {
                    text-align: right
                }
            }
        }
        .list {
            .productActionBar {
                .adminBtn {
                    .add {
                        width: 100%;
                        padding: 7px;
                    }
                }
                .addToBasketButton {
                    .add {
                        width: 100%;
                        padding: 7px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 440px) {
        .grid {
            .addToBasketButton {
                .quantityButtons {
                    text-align: left;
                }
            }
        }
    }
</style>
